define(['app'], function(app) {
  
  var _scrolling = {

    init: function() {

      app.subscribe('scrolling/scrollTo', function(element, duration, easing) {
        _scrolling._scrollTo(element, duration, easing);
      });

    },

    /**
     * Animated scroll to a selected element on the page
     * @param {string} element
     * @param {int} duration
     * @param {boolean} easing
     * @returns {boolean}
     */
    _scrollTo: function(element, duration, easing) {

      var body = document.body,
        html = document.documentElement,
        checkScrollDirection,
        timeoutID,
        stopPoint,
        scrollDistance,
        endPage,
        currentPos,
        currentPosMultiplier,
        scrollDest,
        animSpeed,
        pixelsAboveWindowTop,
        pixelsBelowWindowBottom,
        targetBounding;

      var pageHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

      var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

      var distFromTop = window.pageYOffset || body.scrollTop;

      // find target
      if (element.length !== 0) {
        targetBounding = element[0].getBoundingClientRect();
      } else {
        return false;
      }


      // find distance to travel
      var targetTop = targetBounding.top;
      // is the target above the clicking element - will be a negative value if it is
      if (targetTop < 0) {
        checkScrollDirection = true; // scrolling up

        // will the page still have content above the top of the window
        pixelsAboveWindowTop = (distFromTop - Math.abs(targetTop));
        // set scroll points
        // used when space reamins above the fold
        stopPoint = distFromTop - Math.abs(targetTop);
        // used when scrolling to bottom
        scrollDistance = distFromTop;
        endPage = distFromTop - scrollDistance;
        //used by both parts of scroll
        currentPos = distFromTop;

      } else {
        checkScrollDirection = false; // scrolling down

        // will the page still have content below bottom of the window
        pixelsBelowWindowBottom = ((pageHeight - distFromTop) - targetTop) - windowHeight;
        // scroll points
        // used when space reamins below the fold
        stopPoint = distFromTop + targetTop;
        // used when scrolling to bottom
        scrollDistance = (pageHeight - distFromTop) - windowHeight;
        endPage = distFromTop + scrollDistance;
        //used by both parts of scroll
        currentPos = distFromTop;
      }

      // speed setting
      if (!duration) {
        animSpeed = 2000 / scrollDistance;
      } else {
        animSpeed = duration / scrollDistance;
      }

      if (easing < 10) {
        // set the frame rate to higher as the setTimeout won't run fast enough
        currentPosMultiplier = 10;
      } else {
        currentPosMultiplier = 5;
      }

      // function to handle scroll
      function scrollDown(currentPos, stopPoint, endPage) {
        timeoutID = setTimeout(function() {

          currentPos += currentPosMultiplier;
          window.scrollTo(0, currentPos);

          if (stopPoint === null) {
            scrollDest = endPage;
          } else {
            scrollDest = stopPoint;
          }

          if (currentPos === (0.8 * scrollDest)) {
            currentPosMultiplier = currentPosMultiplier * 0.3;
          }

          if (currentPos < scrollDest) {
            scrollDown(currentPos, stopPoint, endPage);
          } else {
            stopTimer(timeoutID);
          }

        }, animSpeed);
      }

      // function to handle scroll
      function scrollUp(currentPos, stopPoint, endPage) {
        timeoutID = setTimeout(function() {
          currentPos -= currentPosMultiplier;
          window.scrollTo(0, currentPos);

          if (stopPoint === null) {
            scrollDest = endPage;
          } else {
            scrollDest = stopPoint;
          }

          if (currentPos === (0.8 * scrollDest)) {
            currentPosMultiplier = currentPosMultiplier * 0.3;
          }

          if (currentPos > scrollDest) {
            scrollUp(currentPos, stopPoint, endPage);
          } else {
            stopTimer(timeoutID);
          }

        }, animSpeed);
      }

      function stopTimer(timeoutID) {
        clearTimeout(timeoutID);
      }

      // scrolling up
      if (checkScrollDirection) {
        // if there are pixels left then it should only move this distance
        // there is space below the fold so endPage is set to null
        if (pixelsAboveWindowTop > 0) {
          scrollUp(currentPos, stopPoint, null);
        }
        // no pixels left below the fold so stopPoint is set to null
        else {
          scrollUp(currentPos, null, endPage);
        }
      }
      // scrolling down
      else {
        // if there are pixels left then it should only move this distance
        // there is space below the fold so endPage is set to null
        if (pixelsBelowWindowBottom > 0) {
          scrollDown(currentPos, stopPoint, null);
        }
        // no pixels left below the fold so stopPoint is set to null
        else {
          scrollDown(currentPos, null, endPage);
        }
      }

      return true;

    }

  };

  _scrolling.init();

  return {
    scrollTo: function(element, duration, easing) {
      _scrolling.scrollTo(element, duration, easing);
    }
  };

});
